











































import { Component, Prop, PropSync, Watch, } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'Index' })
export default class Index extends VueBase {
	@PropSync("show", { required: true }) showProp: any
	@Prop() data: any

	get visible() {
		if (this.showProp) {
			this.init()
		}
		return this.showProp
	}
	set visible(val: any) {
		this.showProp = val
	}

	private origin = window.location.origin
	private token: any = ''
  async getProjectToken() {
    const { data, status } = await this.services.project.getProjectToken(this.$route.params.pid)
    if (status != 201) return
    this.token = data.token
  }
  private versionList: any = []
  async getVersionList(token?: string | undefined) {
    const res = await this.services.project.versionList(this.$route.params.pid)
    if (res.status === 201) {
      this.versionList = res.data
    }
  }
	private vName: any = ''
  private downloadUrl: any = ''
	private shell: any = ''

	@Watch('vName', { immediate: true, deep: true })
	onChange() {
		this.formatter()
	}
	async init() {
		this.vName = this.data.version_name
		this.getVersionList()
    await this.getProjectToken()
    this.formatter()
	}
	formatter() {
		this.shell = `curl -X GET "${this.origin}/microview/v2/agent/download?url=${
        this.origin
      }&language=java&token=${
        this.token
      }&projectName=${
        (this.data.name && encodeURI(this.data.name)) ||
        'Demo%20Project'
      }&projectVersion=${
        (this.vName && encodeURI(this.vName)) || 'V1.0'
      }&template_id=${
        this.data.template_id
      }" -H "Authorization: Token ${
        this.token
      }" -o microview-agent.jar -k`
    this.downloadUrl = this.origin +
      '/api/v1/agent/download?url=' +
      this.origin +
      '&language=java' +
      `&token=${this.token}&projectName=${
        (this.data.name && encodeURI(this.data.name)) ||
        'Demo%20Project'
      }&projectVersion=${
        (this.vName && encodeURI(this.vName)) || 'V1.0'
      }&template_id=${this.data.template_id}`
	}
	download() {
		// console.log(this.downloadUrl);return;
		window.open(this.downloadUrl)
	}
	handleGo() {
		window.open('/deployment/deploy')
	}
}
